<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.shops') }}</h5>
                <DataTable
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-shops"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTermCode" :placeholder="$t('search.byCode')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="activeFilter" :options="activeOptions" optionLabel="option" :placeholder="$t('search.byStatus')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="blockedModel" :options="blockedOptions" optionLabel="option" :placeholder="$t('search.byStatus')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-2 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="code" :header="$t('system.code')" :sortable="true"></Column>
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column field="franchise" :header="$t('menu.agents')" :sortable="true">
                        <template #body="{ data }">
                            <Button v-if="hasAccess(availableMenus, 'agents/edit')" :label="data.franchise" class="p-button-text p-button-plain p-mr-2 p-mb-2" @click="goto('agents/edit', { id: data.franchiseId })" />
                            <span v-else>{{ data.franchise }}</span>
                        </template>
                    </Column>
                    <Column field="region" :header="$t('menu.regions')" :sortable="true">
                        <template #body="{ data }">
                            <Button v-if="hasAccess(availableMenus, 'regions/edit')" :label="data.region" class="p-button-text p-button-plain p-mr-2 p-mb-2" @click="goto('regions/edit', { id: data.regionId })" />
                            <span v-else>{{ data.region }}</span>
                        </template></Column
                    >
                    <Column field="blocked" :header="$t('general.blocked')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge blocked-' + data.blocked">{{ getBlockedOptionText(data.blocked) }}</span>
                        </template>
                    </Column>
                    <Column field="active" :header="$t('general.status')" :style="{ width: '200px' }">
                        <template #body="{ data }">
                            <span :class="'custom-badge status-' + data.active">{{ data.active ? $t('general.active') : $t('general.inactive') }}</span>
                        </template>
                    </Column>
                    <Column :exportable="false" class="p-text-right" v-show="hasAccess(availableMenus, editPage)">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data._id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            BoApi: new BoApi(),
            searchTerm: '',
            searchTermCode: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'shops',
            activeFilter: { id: 'all', option: 'All' },
            defaultActiveFilterId: 'all',
            blockedModel: { id: -1, option: 'All' },
            defaultBlockedId: -1,
            filtersInitializationDone: false,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        activeOptions() {
            return [
                {
                    id: 'all',
                    option: this.$t('general.all'),
                },
                {
                    id: 'active',
                    option: this.$t('general.active'),
                },
                {
                    id: 'inactive',
                    option: this.$t('general.inactive'),
                },
            ];
        },
        blockedOptions() {
            return [
                {
                    id: -1,
                    option: this.$t('general.all'),
                },
                {
                    id: 0,
                    option: this.$t('system.notBlocked'),
                },
                {
                    id: 1,
                    option: this.$t('system.blocked'),
                },
                {
                    id: 2,
                    option: this.$t('system.blockedPayments'),
                },
                {
                    id: 3,
                    option: this.$t('system.blockedIncomes'),
                },
            ];
        },
        availableMenus() {
            return this.$store.getters['user/availableMenus'];
        },
    },
    watch: {
        activeFilter() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        blockedModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
    },
    mounted() {
        if (this.storeFilterData != undefined) {
            this.searchTerm = this.storeFilterData.data.search;
            this.searchTermCode = this.storeFilterData.data.searchCode;

            this.sort = this.storeFilterData.data.sort;
            this.activeFilter = this.activeOptions.find((s) => s.id == this.storeFilterData.data.filter);
            this.blockedModel = this.blockedOptions.find((s) => s.id == this.storeFilterData.data.blocked);
            this.readItems(this.storeFilterData.data.page);
        } else {
            this.readItems();
        }
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            const params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                searchCode: this.searchTermCode,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                blocked: this.notNullOrEmptyObject(this.blockedModel) ? this.blockedModel.id : this.defaultBlockedId,
            };
            this.BoApi.getShopsDetails(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalPages = response.data.total;
                    this.loading = false;
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        composeAndSaveFiltersToStore() {
            let filters = {
                per_page: this.perPage,
                page: this.crtPage,
                search: this.searchTerm,
                searchCode: this.searchTermCode,
                sort: this.sort,
                filter: this.notNullOrEmptyObject(this.activeFilter) ? this.activeFilter.id : this.defaultActiveFilterId,
                blocked: this.notNullOrEmptyObject(this.blockedModel) ? this.blockedModel.id : this.defaultBlockedId,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        search: debounce(function () {
            this.composeAndSaveFiltersToStore();
            this.readItems();
        }, 400),
        getBlockedOptionText(id) {
            var text = this.blockedOptions.find((s) => s.id == id);
            return this.notNullOrEmptyObject(text) ? text.option : '';
        },
    },
};
</script>
